import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
/**
 * Constantes spécifiques alvéole
 */
export const vousetessur = 'Météo sanitaire';
export const titleAlveole = 'Météo sanitaire';

export const alveoleLogoSvg = "./../images/icon-alv-docs-veto.svg";
/************************ DeV ******************************************************/

export const zUrlBackNotifications = "https://api-servicenotification-dev.cristal-hub.fr";
export const zAPICategoryMeteoSanitaires = "https://api-meteosanitaire-dev.cristal-hub.fr/api/category_meteo_sanitaires";
export const zAPIMeteoSanitaires = "https://api-meteosanitaire-dev.cristal-hub.fr/api/meteo_sanitaires";
export const zUrlBack  = "https://api-meteosanitaire-dev.cristal-hub.fr";

/************************ LOCAL DeV ************************************************/
// export const zUrlBack = "https://localhost:8000"
// export const zUrlBackNotifications = "https://localhost:8001";
// export const zAPIMeteoSanitaires = "https://localhost:8000/api/meteo_sanitaires";
// export const zAPICategoryMeteoSanitaires = "http://localhost:8000/api/category_meteo_sanitaires";

export const CristalContainerHeaderTitle = styled(Paper)(({theme}) => ({
    backgroundColor: 'unset',
    backgroundImage: 'unset',
    boxShadow: 'unset',
    padding: theme.spacing(1),
}));

export const CristalTextMessage = styled(TextField)(({theme}) => ({
    '& label': {
        fontSize: '16px',
        color: theme.palette.mode === 'light' ? '#00508F' : '#ffffff ',
        fontFamily: 'PoppinsRegular',
    },
    '& input': {
        paddingRight: '48px', // Ajustement pour le label
    },
    '& textarea': {
        fontFamily: 'PoppinsRegular',
    },
    '& fieldset': {
        fontSize: '16px',
        borderColor: theme.palette.mode === 'light' ? '#00508F' : '#ffffff',
        borderRadius: '10px',
    },
    '& .MuiInputBase-adornedEnd': {
        paddingRight: '16px'
    },
    marginBottom: '14px'
}));

/************************ Constantes ***********************************************/
export const zURLhome = "https://home-dev.cristal-hub.fr/customer";
export const zURLprofile = "https://profile-dev.cristal-hub.fr";
